/* eslint-disable */
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.ChannelConfiguration', null, global);
goog.exportSymbol('proto.ChannelConversion', null, global);
goog.exportSymbol('proto.DataChannel0', null, global);
goog.exportSymbol('proto.DataChannel1', null, global);
goog.exportSymbol('proto.DataChannel2', null, global);
goog.exportSymbol('proto.DataChannel3', null, global);
goog.exportSymbol('proto.DataChannel4', null, global);
goog.exportSymbol('proto.HeartSeatConfiguration', null, global);
goog.exportSymbol('proto.HeartSeatStatus', null, global);
goog.exportSymbol('proto.HeartSeatStatus.FirmwareAction', null, global);
goog.exportSymbol('proto.HeartSeatStatus.FirmwareStatus', null, global);
goog.exportSymbol('proto.OldHeartSeatConfiguration', null, global);
goog.exportSymbol('proto.PpgExtra', null, global);
goog.exportSymbol('proto.Recording', null, global);
goog.exportSymbol('proto.Recording.Reason', null, global);
goog.exportSymbol('proto.RecordingConfiguration', null, global);
goog.exportSymbol('proto.RecordingFrame', null, global);
goog.exportSymbol('proto.RecordingStatus', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RecordingStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RecordingStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.RecordingStatus.displayName = 'proto.RecordingStatus';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RecordingStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.RecordingStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RecordingStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecordingStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    lengthSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isRecording: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RecordingStatus}
 */
proto.RecordingStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RecordingStatus;
  return proto.RecordingStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RecordingStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RecordingStatus}
 */
proto.RecordingStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLengthSeconds(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecording(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RecordingStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RecordingStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RecordingStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecordingStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLengthSeconds();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getIsRecording();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 length_seconds = 1;
 * @return {number}
 */
proto.RecordingStatus.prototype.getLengthSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.RecordingStatus.prototype.setLengthSeconds = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_recording = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.RecordingStatus.prototype.getIsRecording = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.RecordingStatus.prototype.setIsRecording = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Recording = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Recording.repeatedFields_, null);
};
goog.inherits(proto.Recording, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Recording.displayName = 'proto.Recording';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Recording.repeatedFields_ = [31,32];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Recording.prototype.toObject = function(opt_includeInstance) {
  return proto.Recording.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Recording} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Recording.toObject = function(includeInstance, msg) {
  var f, obj = {
    firmwareVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startUtcTimestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 6, 0),
    framesPerSecond: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ecgSampleRate: jspb.Message.getFieldWithDefault(msg, 8, 0),
    ppgSampleRate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    bcgSampleRate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    weightSampleRate: jspb.Message.getFieldWithDefault(msg, 11, 0),
    formatVersion: jspb.Message.getFieldWithDefault(msg, 12, 0),
    hardwareVersion: jspb.Message.getFieldWithDefault(msg, 13, ""),
    recordingConfig: (f = msg.getRecordingConfig()) && proto.RecordingConfiguration.toObject(includeInstance, f),
    channelConfigsList: jspb.Message.toObjectList(msg.getChannelConfigsList(),
    proto.ChannelConfiguration.toObject, includeInstance),
    framesList: jspb.Message.toObjectList(msg.getFramesList(),
    proto.RecordingFrame.toObject, includeInstance),
    error: jspb.Message.getFieldWithDefault(msg, 64, 0),
    durationSeconds: +jspb.Message.getFieldWithDefault(msg, 65, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Recording}
 */
proto.Recording.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Recording;
  return proto.Recording.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Recording} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Recording}
 */
proto.Recording.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartUtcTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 6:
      var value = /** @type {!proto.Recording.Reason} */ (reader.readEnum());
      msg.setReason(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFramesPerSecond(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEcgSampleRate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPpgSampleRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBcgSampleRate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWeightSampleRate(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFormatVersion(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareVersion(value);
      break;
    case 16:
      var value = new proto.RecordingConfiguration;
      reader.readMessage(value,proto.RecordingConfiguration.deserializeBinaryFromReader);
      msg.setRecordingConfig(value);
      break;
    case 31:
      var value = new proto.ChannelConfiguration;
      reader.readMessage(value,proto.ChannelConfiguration.deserializeBinaryFromReader);
      msg.addChannelConfigs(value);
      break;
    case 32:
      var value = new proto.RecordingFrame;
      reader.readMessage(value,proto.RecordingFrame.deserializeBinaryFromReader);
      msg.addFrames(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setError(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDurationSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Recording.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Recording.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Recording} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Recording.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartUtcTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReason();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getFramesPerSecond();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getEcgSampleRate();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getPpgSampleRate();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getBcgSampleRate();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getWeightSampleRate();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getFormatVersion();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getHardwareVersion();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRecordingConfig();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.RecordingConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getChannelConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      31,
      f,
      proto.ChannelConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getFramesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.RecordingFrame.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f !== 0) {
    writer.writeUint32(
      64,
      f
    );
  }
  f = message.getDurationSeconds();
  if (f !== 0.0) {
    writer.writeFloat(
      65,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.Recording.Reason = {
  ECG_LEAD_ON: 0,
  TEST: 1,
  CALIBRATION: 2,
  MANUFACTURING: 3,
  DEV: 4
};

/**
 * optional string firmware_version = 2;
 * @return {string}
 */
proto.Recording.prototype.getFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.Recording.prototype.setFirmwareVersion = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 start_utc_timestamp = 3;
 * @return {number}
 */
proto.Recording.prototype.getStartUtcTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.Recording.prototype.setStartUtcTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string serial_number = 4;
 * @return {string}
 */
proto.Recording.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.Recording.prototype.setSerialNumber = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string uid = 5;
 * @return {string}
 */
proto.Recording.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.Recording.prototype.setUid = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Reason reason = 6;
 * @return {!proto.Recording.Reason}
 */
proto.Recording.prototype.getReason = function() {
  return /** @type {!proto.Recording.Reason} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.Recording.Reason} value */
proto.Recording.prototype.setReason = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional uint32 frames_per_second = 7;
 * @return {number}
 */
proto.Recording.prototype.getFramesPerSecond = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.Recording.prototype.setFramesPerSecond = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 ecg_sample_rate = 8;
 * @return {number}
 */
proto.Recording.prototype.getEcgSampleRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.Recording.prototype.setEcgSampleRate = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 ppg_sample_rate = 9;
 * @return {number}
 */
proto.Recording.prototype.getPpgSampleRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.Recording.prototype.setPpgSampleRate = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 bcg_sample_rate = 10;
 * @return {number}
 */
proto.Recording.prototype.getBcgSampleRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.Recording.prototype.setBcgSampleRate = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 weight_sample_rate = 11;
 * @return {number}
 */
proto.Recording.prototype.getWeightSampleRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.Recording.prototype.setWeightSampleRate = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 format_version = 12;
 * @return {number}
 */
proto.Recording.prototype.getFormatVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.Recording.prototype.setFormatVersion = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string hardware_version = 13;
 * @return {string}
 */
proto.Recording.prototype.getHardwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/** @param {string} value */
proto.Recording.prototype.setHardwareVersion = function(value) {
  jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional RecordingConfiguration recording_config = 16;
 * @return {?proto.RecordingConfiguration}
 */
proto.Recording.prototype.getRecordingConfig = function() {
  return /** @type{?proto.RecordingConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.RecordingConfiguration, 16));
};


/** @param {?proto.RecordingConfiguration|undefined} value */
proto.Recording.prototype.setRecordingConfig = function(value) {
  jspb.Message.setWrapperField(this, 16, value);
};


proto.Recording.prototype.clearRecordingConfig = function() {
  this.setRecordingConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Recording.prototype.hasRecordingConfig = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated ChannelConfiguration channel_configs = 31;
 * @return {!Array<!proto.ChannelConfiguration>}
 */
proto.Recording.prototype.getChannelConfigsList = function() {
  return /** @type{!Array<!proto.ChannelConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChannelConfiguration, 31));
};


/** @param {!Array<!proto.ChannelConfiguration>} value */
proto.Recording.prototype.setChannelConfigsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 31, value);
};


/**
 * @param {!proto.ChannelConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChannelConfiguration}
 */
proto.Recording.prototype.addChannelConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 31, opt_value, proto.ChannelConfiguration, opt_index);
};


proto.Recording.prototype.clearChannelConfigsList = function() {
  this.setChannelConfigsList([]);
};


/**
 * repeated RecordingFrame frames = 32;
 * @return {!Array<!proto.RecordingFrame>}
 */
proto.Recording.prototype.getFramesList = function() {
  return /** @type{!Array<!proto.RecordingFrame>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RecordingFrame, 32));
};


/** @param {!Array<!proto.RecordingFrame>} value */
proto.Recording.prototype.setFramesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.RecordingFrame=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RecordingFrame}
 */
proto.Recording.prototype.addFrames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.RecordingFrame, opt_index);
};


proto.Recording.prototype.clearFramesList = function() {
  this.setFramesList([]);
};


/**
 * optional uint32 error = 64;
 * @return {number}
 */
proto.Recording.prototype.getError = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 64, 0));
};


/** @param {number} value */
proto.Recording.prototype.setError = function(value) {
  jspb.Message.setProto3IntField(this, 64, value);
};


/**
 * optional float duration_seconds = 65;
 * @return {number}
 */
proto.Recording.prototype.getDurationSeconds = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 65, 0.0));
};


/** @param {number} value */
proto.Recording.prototype.setDurationSeconds = function(value) {
  jspb.Message.setProto3FloatField(this, 65, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DataChannel0 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DataChannel0.repeatedFields_, null);
};
goog.inherits(proto.DataChannel0, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.DataChannel0.displayName = 'proto.DataChannel0';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DataChannel0.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DataChannel0.prototype.toObject = function(opt_includeInstance) {
  return proto.DataChannel0.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DataChannel0} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel0.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dataList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DataChannel0}
 */
proto.DataChannel0.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DataChannel0;
  return proto.DataChannel0.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DataChannel0} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DataChannel0}
 */
proto.DataChannel0.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedSint32());
      msg.setDataList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DataChannel0.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DataChannel0.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DataChannel0} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel0.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedSint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 channel_id = 1;
 * @return {number}
 */
proto.DataChannel0.prototype.getChannelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.DataChannel0.prototype.setChannelId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated sint32 data = 2;
 * @return {!Array<number>}
 */
proto.DataChannel0.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.DataChannel0.prototype.setDataList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.DataChannel0.prototype.addData = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.DataChannel0.prototype.clearDataList = function() {
  this.setDataList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DataChannel1 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DataChannel1.repeatedFields_, null);
};
goog.inherits(proto.DataChannel1, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.DataChannel1.displayName = 'proto.DataChannel1';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DataChannel1.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DataChannel1.prototype.toObject = function(opt_includeInstance) {
  return proto.DataChannel1.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DataChannel1} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel1.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dataList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DataChannel1}
 */
proto.DataChannel1.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DataChannel1;
  return proto.DataChannel1.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DataChannel1} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DataChannel1}
 */
proto.DataChannel1.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedSint32());
      msg.setDataList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DataChannel1.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DataChannel1.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DataChannel1} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel1.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedSint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 channel_id = 1;
 * @return {number}
 */
proto.DataChannel1.prototype.getChannelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.DataChannel1.prototype.setChannelId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated sint32 data = 2;
 * @return {!Array<number>}
 */
proto.DataChannel1.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.DataChannel1.prototype.setDataList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.DataChannel1.prototype.addData = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.DataChannel1.prototype.clearDataList = function() {
  this.setDataList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DataChannel2 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DataChannel2.repeatedFields_, null);
};
goog.inherits(proto.DataChannel2, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.DataChannel2.displayName = 'proto.DataChannel2';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DataChannel2.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DataChannel2.prototype.toObject = function(opt_includeInstance) {
  return proto.DataChannel2.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DataChannel2} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel2.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dataList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DataChannel2}
 */
proto.DataChannel2.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DataChannel2;
  return proto.DataChannel2.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DataChannel2} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DataChannel2}
 */
proto.DataChannel2.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedSint32());
      msg.setDataList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DataChannel2.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DataChannel2.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DataChannel2} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel2.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedSint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 channel_id = 1;
 * @return {number}
 */
proto.DataChannel2.prototype.getChannelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.DataChannel2.prototype.setChannelId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated sint32 data = 2;
 * @return {!Array<number>}
 */
proto.DataChannel2.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.DataChannel2.prototype.setDataList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.DataChannel2.prototype.addData = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.DataChannel2.prototype.clearDataList = function() {
  this.setDataList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DataChannel3 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DataChannel3.repeatedFields_, null);
};
goog.inherits(proto.DataChannel3, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.DataChannel3.displayName = 'proto.DataChannel3';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DataChannel3.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DataChannel3.prototype.toObject = function(opt_includeInstance) {
  return proto.DataChannel3.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DataChannel3} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel3.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dataList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DataChannel3}
 */
proto.DataChannel3.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DataChannel3;
  return proto.DataChannel3.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DataChannel3} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DataChannel3}
 */
proto.DataChannel3.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedSint32());
      msg.setDataList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DataChannel3.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DataChannel3.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DataChannel3} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel3.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedSint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 channel_id = 1;
 * @return {number}
 */
proto.DataChannel3.prototype.getChannelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.DataChannel3.prototype.setChannelId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated sint32 data = 2;
 * @return {!Array<number>}
 */
proto.DataChannel3.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.DataChannel3.prototype.setDataList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.DataChannel3.prototype.addData = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.DataChannel3.prototype.clearDataList = function() {
  this.setDataList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DataChannel4 = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DataChannel4.repeatedFields_, null);
};
goog.inherits(proto.DataChannel4, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.DataChannel4.displayName = 'proto.DataChannel4';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DataChannel4.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DataChannel4.prototype.toObject = function(opt_includeInstance) {
  return proto.DataChannel4.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DataChannel4} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel4.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dataList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DataChannel4}
 */
proto.DataChannel4.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DataChannel4;
  return proto.DataChannel4.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DataChannel4} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DataChannel4}
 */
proto.DataChannel4.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedSint32());
      msg.setDataList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DataChannel4.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DataChannel4.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DataChannel4} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DataChannel4.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writePackedSint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 channel_id = 1;
 * @return {number}
 */
proto.DataChannel4.prototype.getChannelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.DataChannel4.prototype.setChannelId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated sint32 data = 2;
 * @return {!Array<number>}
 */
proto.DataChannel4.prototype.getDataList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.DataChannel4.prototype.setDataList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.DataChannel4.prototype.addData = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.DataChannel4.prototype.clearDataList = function() {
  this.setDataList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RecordingFrame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RecordingFrame.repeatedFields_, null);
};
goog.inherits(proto.RecordingFrame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.RecordingFrame.displayName = 'proto.RecordingFrame';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RecordingFrame.repeatedFields_ = [2,3,4,5,6,7,8,9,10,11,12,17,18,19,20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RecordingFrame.prototype.toObject = function(opt_includeInstance) {
  return proto.RecordingFrame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RecordingFrame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecordingFrame.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ecgList: jspb.Message.getRepeatedField(msg, 2),
    ppgIrList: jspb.Message.getRepeatedField(msg, 3),
    ppgRedList: jspb.Message.getRepeatedField(msg, 4),
    bcgBlList: jspb.Message.getRepeatedField(msg, 5),
    bcgFlList: jspb.Message.getRepeatedField(msg, 6),
    bcgFrList: jspb.Message.getRepeatedField(msg, 7),
    bcgBrList: jspb.Message.getRepeatedField(msg, 8),
    weightBlList: jspb.Message.getRepeatedField(msg, 9),
    weightFlList: jspb.Message.getRepeatedField(msg, 10),
    weightFrList: jspb.Message.getRepeatedField(msg, 11),
    weightBrList: jspb.Message.getRepeatedField(msg, 12),
    ppgIrLed: jspb.Message.getFieldWithDefault(msg, 13, 0),
    ppgRedLed: jspb.Message.getFieldWithDefault(msg, 14, 0),
    ppgIrOffset: jspb.Message.getFieldWithDefault(msg, 15, 0),
    ppgRedOffset: jspb.Message.getFieldWithDefault(msg, 16, 0),
    channels0List: jspb.Message.toObjectList(msg.getChannels0List(),
    proto.DataChannel0.toObject, includeInstance),
    channels1List: jspb.Message.toObjectList(msg.getChannels1List(),
    proto.DataChannel1.toObject, includeInstance),
    channels2List: jspb.Message.toObjectList(msg.getChannels2List(),
    proto.DataChannel2.toObject, includeInstance),
    channels3List: jspb.Message.toObjectList(msg.getChannels3List(),
    proto.DataChannel3.toObject, includeInstance),
    channels4List: jspb.Message.toObjectList(msg.getChannels4List(),
    proto.DataChannel4.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RecordingFrame}
 */
proto.RecordingFrame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RecordingFrame;
  return proto.RecordingFrame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RecordingFrame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RecordingFrame}
 */
proto.RecordingFrame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setEcgList(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setPpgIrList(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setPpgRedList(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setBcgBlList(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setBcgFlList(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setBcgFrList(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setBcgBrList(value);
      break;
    case 9:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setWeightBlList(value);
      break;
    case 10:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setWeightFlList(value);
      break;
    case 11:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setWeightFrList(value);
      break;
    case 12:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setWeightBrList(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPpgIrLed(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPpgRedLed(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPpgIrOffset(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPpgRedOffset(value);
      break;
    case 17:
      var value = new proto.DataChannel0;
      reader.readMessage(value,proto.DataChannel0.deserializeBinaryFromReader);
      msg.addChannels0(value);
      break;
    case 18:
      var value = new proto.DataChannel1;
      reader.readMessage(value,proto.DataChannel1.deserializeBinaryFromReader);
      msg.addChannels1(value);
      break;
    case 19:
      var value = new proto.DataChannel2;
      reader.readMessage(value,proto.DataChannel2.deserializeBinaryFromReader);
      msg.addChannels2(value);
      break;
    case 20:
      var value = new proto.DataChannel3;
      reader.readMessage(value,proto.DataChannel3.deserializeBinaryFromReader);
      msg.addChannels3(value);
      break;
    case 21:
      var value = new proto.DataChannel4;
      reader.readMessage(value,proto.DataChannel4.deserializeBinaryFromReader);
      msg.addChannels4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RecordingFrame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RecordingFrame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RecordingFrame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecordingFrame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEcgList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getPpgIrList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
  f = message.getPpgRedList();
  if (f.length > 0) {
    writer.writePackedUint32(
      4,
      f
    );
  }
  f = message.getBcgBlList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
  f = message.getBcgFlList();
  if (f.length > 0) {
    writer.writePackedUint32(
      6,
      f
    );
  }
  f = message.getBcgFrList();
  if (f.length > 0) {
    writer.writePackedUint32(
      7,
      f
    );
  }
  f = message.getBcgBrList();
  if (f.length > 0) {
    writer.writePackedUint32(
      8,
      f
    );
  }
  f = message.getWeightBlList();
  if (f.length > 0) {
    writer.writePackedUint32(
      9,
      f
    );
  }
  f = message.getWeightFlList();
  if (f.length > 0) {
    writer.writePackedUint32(
      10,
      f
    );
  }
  f = message.getWeightFrList();
  if (f.length > 0) {
    writer.writePackedUint32(
      11,
      f
    );
  }
  f = message.getWeightBrList();
  if (f.length > 0) {
    writer.writePackedUint32(
      12,
      f
    );
  }
  f = message.getPpgIrLed();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getPpgRedLed();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getPpgIrOffset();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getPpgRedOffset();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getChannels0List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.DataChannel0.serializeBinaryToWriter
    );
  }
  f = message.getChannels1List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.DataChannel1.serializeBinaryToWriter
    );
  }
  f = message.getChannels2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.DataChannel2.serializeBinaryToWriter
    );
  }
  f = message.getChannels3List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.DataChannel3.serializeBinaryToWriter
    );
  }
  f = message.getChannels4List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.DataChannel4.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 index = 1;
 * @return {number}
 */
proto.RecordingFrame.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.RecordingFrame.prototype.setIndex = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 ecg = 2;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getEcgList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setEcgList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addEcg = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.RecordingFrame.prototype.clearEcgList = function() {
  this.setEcgList([]);
};


/**
 * repeated uint32 ppg_ir = 3;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getPpgIrList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setPpgIrList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addPpgIr = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.RecordingFrame.prototype.clearPpgIrList = function() {
  this.setPpgIrList([]);
};


/**
 * repeated uint32 ppg_red = 4;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getPpgRedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setPpgRedList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addPpgRed = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.RecordingFrame.prototype.clearPpgRedList = function() {
  this.setPpgRedList([]);
};


/**
 * repeated uint32 bcg_bl = 5;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getBcgBlList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setBcgBlList = function(value) {
  jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addBcgBl = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


proto.RecordingFrame.prototype.clearBcgBlList = function() {
  this.setBcgBlList([]);
};


/**
 * repeated uint32 bcg_fl = 6;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getBcgFlList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setBcgFlList = function(value) {
  jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addBcgFl = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


proto.RecordingFrame.prototype.clearBcgFlList = function() {
  this.setBcgFlList([]);
};


/**
 * repeated uint32 bcg_fr = 7;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getBcgFrList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setBcgFrList = function(value) {
  jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addBcgFr = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


proto.RecordingFrame.prototype.clearBcgFrList = function() {
  this.setBcgFrList([]);
};


/**
 * repeated uint32 bcg_br = 8;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getBcgBrList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setBcgBrList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addBcgBr = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


proto.RecordingFrame.prototype.clearBcgBrList = function() {
  this.setBcgBrList([]);
};


/**
 * repeated uint32 weight_bl = 9;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getWeightBlList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setWeightBlList = function(value) {
  jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addWeightBl = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


proto.RecordingFrame.prototype.clearWeightBlList = function() {
  this.setWeightBlList([]);
};


/**
 * repeated uint32 weight_fl = 10;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getWeightFlList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setWeightFlList = function(value) {
  jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addWeightFl = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


proto.RecordingFrame.prototype.clearWeightFlList = function() {
  this.setWeightFlList([]);
};


/**
 * repeated uint32 weight_fr = 11;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getWeightFrList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setWeightFrList = function(value) {
  jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addWeightFr = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


proto.RecordingFrame.prototype.clearWeightFrList = function() {
  this.setWeightFrList([]);
};


/**
 * repeated uint32 weight_br = 12;
 * @return {!Array<number>}
 */
proto.RecordingFrame.prototype.getWeightBrList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array<number>} value */
proto.RecordingFrame.prototype.setWeightBrList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingFrame.prototype.addWeightBr = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


proto.RecordingFrame.prototype.clearWeightBrList = function() {
  this.setWeightBrList([]);
};


/**
 * optional uint32 ppg_ir_led = 13;
 * @return {number}
 */
proto.RecordingFrame.prototype.getPpgIrLed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.RecordingFrame.prototype.setPpgIrLed = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 ppg_red_led = 14;
 * @return {number}
 */
proto.RecordingFrame.prototype.getPpgRedLed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.RecordingFrame.prototype.setPpgRedLed = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 ppg_ir_offset = 15;
 * @return {number}
 */
proto.RecordingFrame.prototype.getPpgIrOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.RecordingFrame.prototype.setPpgIrOffset = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 ppg_red_offset = 16;
 * @return {number}
 */
proto.RecordingFrame.prototype.getPpgRedOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.RecordingFrame.prototype.setPpgRedOffset = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * repeated DataChannel0 channels0 = 17;
 * @return {!Array<!proto.DataChannel0>}
 */
proto.RecordingFrame.prototype.getChannels0List = function() {
  return /** @type{!Array<!proto.DataChannel0>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DataChannel0, 17));
};


/** @param {!Array<!proto.DataChannel0>} value */
proto.RecordingFrame.prototype.setChannels0List = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.DataChannel0=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DataChannel0}
 */
proto.RecordingFrame.prototype.addChannels0 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.DataChannel0, opt_index);
};


proto.RecordingFrame.prototype.clearChannels0List = function() {
  this.setChannels0List([]);
};


/**
 * repeated DataChannel1 channels1 = 18;
 * @return {!Array<!proto.DataChannel1>}
 */
proto.RecordingFrame.prototype.getChannels1List = function() {
  return /** @type{!Array<!proto.DataChannel1>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DataChannel1, 18));
};


/** @param {!Array<!proto.DataChannel1>} value */
proto.RecordingFrame.prototype.setChannels1List = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.DataChannel1=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DataChannel1}
 */
proto.RecordingFrame.prototype.addChannels1 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.DataChannel1, opt_index);
};


proto.RecordingFrame.prototype.clearChannels1List = function() {
  this.setChannels1List([]);
};


/**
 * repeated DataChannel2 channels2 = 19;
 * @return {!Array<!proto.DataChannel2>}
 */
proto.RecordingFrame.prototype.getChannels2List = function() {
  return /** @type{!Array<!proto.DataChannel2>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DataChannel2, 19));
};


/** @param {!Array<!proto.DataChannel2>} value */
proto.RecordingFrame.prototype.setChannels2List = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.DataChannel2=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DataChannel2}
 */
proto.RecordingFrame.prototype.addChannels2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.DataChannel2, opt_index);
};


proto.RecordingFrame.prototype.clearChannels2List = function() {
  this.setChannels2List([]);
};


/**
 * repeated DataChannel3 channels3 = 20;
 * @return {!Array<!proto.DataChannel3>}
 */
proto.RecordingFrame.prototype.getChannels3List = function() {
  return /** @type{!Array<!proto.DataChannel3>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DataChannel3, 20));
};


/** @param {!Array<!proto.DataChannel3>} value */
proto.RecordingFrame.prototype.setChannels3List = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.DataChannel3=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DataChannel3}
 */
proto.RecordingFrame.prototype.addChannels3 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.DataChannel3, opt_index);
};


proto.RecordingFrame.prototype.clearChannels3List = function() {
  this.setChannels3List([]);
};


/**
 * repeated DataChannel4 channels4 = 21;
 * @return {!Array<!proto.DataChannel4>}
 */
proto.RecordingFrame.prototype.getChannels4List = function() {
  return /** @type{!Array<!proto.DataChannel4>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DataChannel4, 21));
};


/** @param {!Array<!proto.DataChannel4>} value */
proto.RecordingFrame.prototype.setChannels4List = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.DataChannel4=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DataChannel4}
 */
proto.RecordingFrame.prototype.addChannels4 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.DataChannel4, opt_index);
};


proto.RecordingFrame.prototype.clearChannels4List = function() {
  this.setChannels4List([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RecordingConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RecordingConfiguration.repeatedFields_, null);
};
goog.inherits(proto.RecordingConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.RecordingConfiguration.displayName = 'proto.RecordingConfiguration';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RecordingConfiguration.repeatedFields_ = [96,97];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RecordingConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.RecordingConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RecordingConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecordingConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    irLedCurrent: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    redLedCurrent: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    isImpedanceEnabled: jspb.Message.getFieldWithDefault(msg, 8, false),
    minWeightLbs: jspb.Message.getFieldWithDefault(msg, 32, 0),
    minSeconds: jspb.Message.getFieldWithDefault(msg, 33, 0),
    maxSeconds: jspb.Message.getFieldWithDefault(msg, 34, 0),
    extraSecondsAtEnd: jspb.Message.getFieldWithDefault(msg, 35, 0),
    weightBlSlope: +jspb.Message.getFieldWithDefault(msg, 64, 0.0),
    weightFlSlope: +jspb.Message.getFieldWithDefault(msg, 65, 0.0),
    weightFrSlope: +jspb.Message.getFieldWithDefault(msg, 66, 0.0),
    weightBrSlope: +jspb.Message.getFieldWithDefault(msg, 67, 0.0),
    weightBlOffset: +jspb.Message.getFieldWithDefault(msg, 68, 0.0),
    weightFlOffset: +jspb.Message.getFieldWithDefault(msg, 69, 0.0),
    weightFrOffset: +jspb.Message.getFieldWithDefault(msg, 70, 0.0),
    weightBrOffset: +jspb.Message.getFieldWithDefault(msg, 71, 0.0),
    ppgIrCalOffset: +jspb.Message.getFieldWithDefault(msg, 72, 0.0),
    ppgRedCalOffset: +jspb.Message.getFieldWithDefault(msg, 73, 0.0),
    ppgIrCalSlope: +jspb.Message.getFieldWithDefault(msg, 74, 0.0),
    ppgRedCalSlope: +jspb.Message.getFieldWithDefault(msg, 75, 0.0),
    redCurrentsMaList: jspb.Message.getRepeatedFloatingPointField(msg, 96),
    irCurrentsMaList: jspb.Message.getRepeatedFloatingPointField(msg, 97),
    ppgIrResistance: +jspb.Message.getFieldWithDefault(msg, 98, 0.0),
    ppgRedResistance: +jspb.Message.getFieldWithDefault(msg, 99, 0.0),
    ppgIrMaxCurrentMa: +jspb.Message.getFieldWithDefault(msg, 100, 0.0),
    ppgRedMaxCurrentMa: +jspb.Message.getFieldWithDefault(msg, 101, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RecordingConfiguration}
 */
proto.RecordingConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RecordingConfiguration;
  return proto.RecordingConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RecordingConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RecordingConfiguration}
 */
proto.RecordingConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIrLedCurrent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRedLedCurrent(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImpedanceEnabled(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinWeightLbs(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinSeconds(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxSeconds(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExtraSecondsAtEnd(value);
      break;
    case 64:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightBlSlope(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightFlSlope(value);
      break;
    case 66:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightFrSlope(value);
      break;
    case 67:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightBrSlope(value);
      break;
    case 68:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightBlOffset(value);
      break;
    case 69:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightFlOffset(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightFrOffset(value);
      break;
    case 71:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightBrOffset(value);
      break;
    case 72:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPpgIrCalOffset(value);
      break;
    case 73:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPpgRedCalOffset(value);
      break;
    case 74:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPpgIrCalSlope(value);
      break;
    case 75:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPpgRedCalSlope(value);
      break;
    case 96:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setRedCurrentsMaList(value);
      break;
    case 97:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setIrCurrentsMaList(value);
      break;
    case 98:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPpgIrResistance(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPpgRedResistance(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPpgIrMaxCurrentMa(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPpgRedMaxCurrentMa(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RecordingConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RecordingConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RecordingConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RecordingConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIrLedCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getRedLedCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getIsImpedanceEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMinWeightLbs();
  if (f !== 0) {
    writer.writeUint32(
      32,
      f
    );
  }
  f = message.getMinSeconds();
  if (f !== 0) {
    writer.writeUint32(
      33,
      f
    );
  }
  f = message.getMaxSeconds();
  if (f !== 0) {
    writer.writeUint32(
      34,
      f
    );
  }
  f = message.getExtraSecondsAtEnd();
  if (f !== 0) {
    writer.writeUint32(
      35,
      f
    );
  }
  f = message.getWeightBlSlope();
  if (f !== 0.0) {
    writer.writeFloat(
      64,
      f
    );
  }
  f = message.getWeightFlSlope();
  if (f !== 0.0) {
    writer.writeFloat(
      65,
      f
    );
  }
  f = message.getWeightFrSlope();
  if (f !== 0.0) {
    writer.writeFloat(
      66,
      f
    );
  }
  f = message.getWeightBrSlope();
  if (f !== 0.0) {
    writer.writeFloat(
      67,
      f
    );
  }
  f = message.getWeightBlOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      68,
      f
    );
  }
  f = message.getWeightFlOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      69,
      f
    );
  }
  f = message.getWeightFrOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      70,
      f
    );
  }
  f = message.getWeightBrOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      71,
      f
    );
  }
  f = message.getPpgIrCalOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      72,
      f
    );
  }
  f = message.getPpgRedCalOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      73,
      f
    );
  }
  f = message.getPpgIrCalSlope();
  if (f !== 0.0) {
    writer.writeFloat(
      74,
      f
    );
  }
  f = message.getPpgRedCalSlope();
  if (f !== 0.0) {
    writer.writeFloat(
      75,
      f
    );
  }
  f = message.getRedCurrentsMaList();
  if (f.length > 0) {
    writer.writePackedFloat(
      96,
      f
    );
  }
  f = message.getIrCurrentsMaList();
  if (f.length > 0) {
    writer.writePackedFloat(
      97,
      f
    );
  }
  f = message.getPpgIrResistance();
  if (f !== 0.0) {
    writer.writeFloat(
      98,
      f
    );
  }
  f = message.getPpgRedResistance();
  if (f !== 0.0) {
    writer.writeFloat(
      99,
      f
    );
  }
  f = message.getPpgIrMaxCurrentMa();
  if (f !== 0.0) {
    writer.writeFloat(
      100,
      f
    );
  }
  f = message.getPpgRedMaxCurrentMa();
  if (f !== 0.0) {
    writer.writeFloat(
      101,
      f
    );
  }
};


/**
 * optional float ir_led_current = 4;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getIrLedCurrent = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setIrLedCurrent = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float red_led_current = 5;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getRedLedCurrent = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setRedLedCurrent = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional bool is_impedance_enabled = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.RecordingConfiguration.prototype.getIsImpedanceEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.RecordingConfiguration.prototype.setIsImpedanceEnabled = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint32 min_weight_lbs = 32;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getMinWeightLbs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setMinWeightLbs = function(value) {
  jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional uint32 min_seconds = 33;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getMinSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setMinSeconds = function(value) {
  jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional uint32 max_seconds = 34;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getMaxSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setMaxSeconds = function(value) {
  jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional uint32 extra_seconds_at_end = 35;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getExtraSecondsAtEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setExtraSecondsAtEnd = function(value) {
  jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional float weight_bl_slope = 64;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getWeightBlSlope = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 64, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setWeightBlSlope = function(value) {
  jspb.Message.setProto3FloatField(this, 64, value);
};


/**
 * optional float weight_fl_slope = 65;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getWeightFlSlope = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 65, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setWeightFlSlope = function(value) {
  jspb.Message.setProto3FloatField(this, 65, value);
};


/**
 * optional float weight_fr_slope = 66;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getWeightFrSlope = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 66, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setWeightFrSlope = function(value) {
  jspb.Message.setProto3FloatField(this, 66, value);
};


/**
 * optional float weight_br_slope = 67;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getWeightBrSlope = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 67, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setWeightBrSlope = function(value) {
  jspb.Message.setProto3FloatField(this, 67, value);
};


/**
 * optional float weight_bl_offset = 68;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getWeightBlOffset = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 68, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setWeightBlOffset = function(value) {
  jspb.Message.setProto3FloatField(this, 68, value);
};


/**
 * optional float weight_fl_offset = 69;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getWeightFlOffset = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 69, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setWeightFlOffset = function(value) {
  jspb.Message.setProto3FloatField(this, 69, value);
};


/**
 * optional float weight_fr_offset = 70;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getWeightFrOffset = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 70, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setWeightFrOffset = function(value) {
  jspb.Message.setProto3FloatField(this, 70, value);
};


/**
 * optional float weight_br_offset = 71;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getWeightBrOffset = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 71, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setWeightBrOffset = function(value) {
  jspb.Message.setProto3FloatField(this, 71, value);
};


/**
 * optional float ppg_ir_cal_offset = 72;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getPpgIrCalOffset = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 72, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setPpgIrCalOffset = function(value) {
  jspb.Message.setProto3FloatField(this, 72, value);
};


/**
 * optional float ppg_red_cal_offset = 73;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getPpgRedCalOffset = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 73, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setPpgRedCalOffset = function(value) {
  jspb.Message.setProto3FloatField(this, 73, value);
};


/**
 * optional float ppg_ir_cal_slope = 74;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getPpgIrCalSlope = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 74, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setPpgIrCalSlope = function(value) {
  jspb.Message.setProto3FloatField(this, 74, value);
};


/**
 * optional float ppg_red_cal_slope = 75;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getPpgRedCalSlope = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 75, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setPpgRedCalSlope = function(value) {
  jspb.Message.setProto3FloatField(this, 75, value);
};


/**
 * repeated float red_currents_mA = 96;
 * @return {!Array<number>}
 */
proto.RecordingConfiguration.prototype.getRedCurrentsMaList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 96));
};


/** @param {!Array<number>} value */
proto.RecordingConfiguration.prototype.setRedCurrentsMaList = function(value) {
  jspb.Message.setField(this, 96, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingConfiguration.prototype.addRedCurrentsMa = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 96, value, opt_index);
};


proto.RecordingConfiguration.prototype.clearRedCurrentsMaList = function() {
  this.setRedCurrentsMaList([]);
};


/**
 * repeated float ir_currents_mA = 97;
 * @return {!Array<number>}
 */
proto.RecordingConfiguration.prototype.getIrCurrentsMaList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 97));
};


/** @param {!Array<number>} value */
proto.RecordingConfiguration.prototype.setIrCurrentsMaList = function(value) {
  jspb.Message.setField(this, 97, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.RecordingConfiguration.prototype.addIrCurrentsMa = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 97, value, opt_index);
};


proto.RecordingConfiguration.prototype.clearIrCurrentsMaList = function() {
  this.setIrCurrentsMaList([]);
};


/**
 * optional float ppg_ir_resistance = 98;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getPpgIrResistance = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 98, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setPpgIrResistance = function(value) {
  jspb.Message.setProto3FloatField(this, 98, value);
};


/**
 * optional float ppg_red_resistance = 99;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getPpgRedResistance = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 99, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setPpgRedResistance = function(value) {
  jspb.Message.setProto3FloatField(this, 99, value);
};


/**
 * optional float ppg_ir_max_current_mA = 100;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getPpgIrMaxCurrentMa = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 100, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setPpgIrMaxCurrentMa = function(value) {
  jspb.Message.setProto3FloatField(this, 100, value);
};


/**
 * optional float ppg_red_max_current_mA = 101;
 * @return {number}
 */
proto.RecordingConfiguration.prototype.getPpgRedMaxCurrentMa = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 101, 0.0));
};


/** @param {number} value */
proto.RecordingConfiguration.prototype.setPpgRedMaxCurrentMa = function(value) {
  jspb.Message.setProto3FloatField(this, 101, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChannelConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ChannelConfiguration.repeatedFields_, proto.ChannelConfiguration.oneofGroups_);
};
goog.inherits(proto.ChannelConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.ChannelConfiguration.displayName = 'proto.ChannelConfiguration';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ChannelConfiguration.repeatedFields_ = [5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ChannelConfiguration.oneofGroups_ = [[6]];

/**
 * @enum {number}
 */
proto.ChannelConfiguration.ExtraCase = {
  EXTRA_NOT_SET: 0,
  PPG_EXTRA: 6
};

/**
 * @return {proto.ChannelConfiguration.ExtraCase}
 */
proto.ChannelConfiguration.prototype.getExtraCase = function() {
  return /** @type {proto.ChannelConfiguration.ExtraCase} */(jspb.Message.computeOneofCase(this, proto.ChannelConfiguration.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChannelConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.ChannelConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChannelConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChannelConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hardwareVersion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    samplesPerSecond: jspb.Message.getFieldWithDefault(msg, 4, 0),
    channelConversionsList: jspb.Message.toObjectList(msg.getChannelConversionsList(),
    proto.ChannelConversion.toObject, includeInstance),
    ppgExtra: (f = msg.getPpgExtra()) && proto.PpgExtra.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChannelConfiguration}
 */
proto.ChannelConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChannelConfiguration;
  return proto.ChannelConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChannelConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChannelConfiguration}
 */
proto.ChannelConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareVersion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSamplesPerSecond(value);
      break;
    case 5:
      var value = new proto.ChannelConversion;
      reader.readMessage(value,proto.ChannelConversion.deserializeBinaryFromReader);
      msg.addChannelConversions(value);
      break;
    case 6:
      var value = new proto.PpgExtra;
      reader.readMessage(value,proto.PpgExtra.deserializeBinaryFromReader);
      msg.setPpgExtra(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChannelConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChannelConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChannelConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChannelConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getHardwareVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSamplesPerSecond();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getChannelConversionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.ChannelConversion.serializeBinaryToWriter
    );
  }
  f = message.getPpgExtra();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.PpgExtra.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ChannelConfiguration.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.ChannelConfiguration.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 channel_id = 2;
 * @return {number}
 */
proto.ChannelConfiguration.prototype.getChannelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.ChannelConfiguration.prototype.setChannelId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string hardware_version = 3;
 * @return {string}
 */
proto.ChannelConfiguration.prototype.getHardwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.ChannelConfiguration.prototype.setHardwareVersion = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 samples_per_second = 4;
 * @return {number}
 */
proto.ChannelConfiguration.prototype.getSamplesPerSecond = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.ChannelConfiguration.prototype.setSamplesPerSecond = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated ChannelConversion channel_conversions = 5;
 * @return {!Array<!proto.ChannelConversion>}
 */
proto.ChannelConfiguration.prototype.getChannelConversionsList = function() {
  return /** @type{!Array<!proto.ChannelConversion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChannelConversion, 5));
};


/** @param {!Array<!proto.ChannelConversion>} value */
proto.ChannelConfiguration.prototype.setChannelConversionsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.ChannelConversion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChannelConversion}
 */
proto.ChannelConfiguration.prototype.addChannelConversions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.ChannelConversion, opt_index);
};


proto.ChannelConfiguration.prototype.clearChannelConversionsList = function() {
  this.setChannelConversionsList([]);
};


/**
 * optional PpgExtra ppg_extra = 6;
 * @return {?proto.PpgExtra}
 */
proto.ChannelConfiguration.prototype.getPpgExtra = function() {
  return /** @type{?proto.PpgExtra} */ (
    jspb.Message.getWrapperField(this, proto.PpgExtra, 6));
};


/** @param {?proto.PpgExtra|undefined} value */
proto.ChannelConfiguration.prototype.setPpgExtra = function(value) {
  jspb.Message.setOneofWrapperField(this, 6, proto.ChannelConfiguration.oneofGroups_[0], value);
};


proto.ChannelConfiguration.prototype.clearPpgExtra = function() {
  this.setPpgExtra(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.ChannelConfiguration.prototype.hasPpgExtra = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChannelConversion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChannelConversion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.ChannelConversion.displayName = 'proto.ChannelConversion';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChannelConversion.prototype.toObject = function(opt_includeInstance) {
  return proto.ChannelConversion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChannelConversion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChannelConversion.toObject = function(includeInstance, msg) {
  var f, obj = {
    slope: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    offset: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    units: jspb.Message.getFieldWithDefault(msg, 3, ""),
    maxValueInUnits: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    minValueInUnits: +jspb.Message.getFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChannelConversion}
 */
proto.ChannelConversion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChannelConversion;
  return proto.ChannelConversion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChannelConversion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChannelConversion}
 */
proto.ChannelConversion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSlope(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnits(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxValueInUnits(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinValueInUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChannelConversion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChannelConversion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChannelConversion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChannelConversion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlope();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getUnits();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMaxValueInUnits();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getMinValueInUnits();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional float slope = 1;
 * @return {number}
 */
proto.ChannelConversion.prototype.getSlope = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.ChannelConversion.prototype.setSlope = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float offset = 2;
 * @return {number}
 */
proto.ChannelConversion.prototype.getOffset = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.ChannelConversion.prototype.setOffset = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string units = 3;
 * @return {string}
 */
proto.ChannelConversion.prototype.getUnits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.ChannelConversion.prototype.setUnits = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float max_value_in_units = 4;
 * @return {number}
 */
proto.ChannelConversion.prototype.getMaxValueInUnits = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.ChannelConversion.prototype.setMaxValueInUnits = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float min_value_in_units = 5;
 * @return {number}
 */
proto.ChannelConversion.prototype.getMinValueInUnits = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.ChannelConversion.prototype.setMinValueInUnits = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PpgExtra = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PpgExtra, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.PpgExtra.displayName = 'proto.PpgExtra';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PpgExtra.prototype.toObject = function(opt_includeInstance) {
  return proto.PpgExtra.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PpgExtra} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PpgExtra.toObject = function(includeInstance, msg) {
  var f, obj = {
    ledResistance: +jspb.Message.getFieldWithDefault(msg, 1, 0.0),
    opticalPathCalSlope: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    opticalPathCalOffset: +jspb.Message.getFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PpgExtra}
 */
proto.PpgExtra.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PpgExtra;
  return proto.PpgExtra.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PpgExtra} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PpgExtra}
 */
proto.PpgExtra.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLedResistance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOpticalPathCalSlope(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOpticalPathCalOffset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PpgExtra.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PpgExtra.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PpgExtra} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PpgExtra.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLedResistance();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getOpticalPathCalSlope();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getOpticalPathCalOffset();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float led_resistance = 1;
 * @return {number}
 */
proto.PpgExtra.prototype.getLedResistance = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 1, 0.0));
};


/** @param {number} value */
proto.PpgExtra.prototype.setLedResistance = function(value) {
  jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float optical_path_cal_slope = 2;
 * @return {number}
 */
proto.PpgExtra.prototype.getOpticalPathCalSlope = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.PpgExtra.prototype.setOpticalPathCalSlope = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float optical_path_cal_offset = 3;
 * @return {number}
 */
proto.PpgExtra.prototype.getOpticalPathCalOffset = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.PpgExtra.prototype.setOpticalPathCalOffset = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.HeartSeatConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.HeartSeatConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.HeartSeatConfiguration.displayName = 'proto.HeartSeatConfiguration';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.HeartSeatConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.HeartSeatConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.HeartSeatConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeartSeatConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxAwakeSeconds: jspb.Message.getFieldWithDefault(msg, 8, 0),
    checkInIntervalSeconds: jspb.Message.getFieldWithDefault(msg, 9, 0),
    maxCloudRetries: jspb.Message.getFieldWithDefault(msg, 10, 0),
    checkInRetryInSeconds: jspb.Message.getFieldWithDefault(msg, 11, 0),
    wifiSsid: jspb.Message.getFieldWithDefault(msg, 16, ""),
    wifiPass: jspb.Message.getFieldWithDefault(msg, 17, ""),
    automaticRecording: jspb.Message.getFieldWithDefault(msg, 18, false),
    automaticUpload: jspb.Message.getFieldWithDefault(msg, 19, false),
    debugLogging: jspb.Message.getFieldWithDefault(msg, 20, false),
    useDevApi: jspb.Message.getFieldWithDefault(msg, 21, false),
    recordingConfig: (f = msg.getRecordingConfig()) && proto.RecordingConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.HeartSeatConfiguration}
 */
proto.HeartSeatConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.HeartSeatConfiguration;
  return proto.HeartSeatConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.HeartSeatConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.HeartSeatConfiguration}
 */
proto.HeartSeatConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxAwakeSeconds(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCheckInIntervalSeconds(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxCloudRetries(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCheckInRetryInSeconds(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setWifiSsid(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWifiPass(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomaticRecording(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomaticUpload(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDebugLogging(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseDevApi(value);
      break;
    case 32:
      var value = new proto.RecordingConfiguration;
      reader.readMessage(value,proto.RecordingConfiguration.deserializeBinaryFromReader);
      msg.setRecordingConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.HeartSeatConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.HeartSeatConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.HeartSeatConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeartSeatConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxAwakeSeconds();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getCheckInIntervalSeconds();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getMaxCloudRetries();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getCheckInRetryInSeconds();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getWifiSsid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getWifiPass();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAutomaticRecording();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getAutomaticUpload();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getDebugLogging();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getUseDevApi();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getRecordingConfig();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.RecordingConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 max_awake_seconds = 8;
 * @return {number}
 */
proto.HeartSeatConfiguration.prototype.getMaxAwakeSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.HeartSeatConfiguration.prototype.setMaxAwakeSeconds = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 check_in_interval_seconds = 9;
 * @return {number}
 */
proto.HeartSeatConfiguration.prototype.getCheckInIntervalSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.HeartSeatConfiguration.prototype.setCheckInIntervalSeconds = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 max_cloud_retries = 10;
 * @return {number}
 */
proto.HeartSeatConfiguration.prototype.getMaxCloudRetries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.HeartSeatConfiguration.prototype.setMaxCloudRetries = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 check_in_retry_in_seconds = 11;
 * @return {number}
 */
proto.HeartSeatConfiguration.prototype.getCheckInRetryInSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.HeartSeatConfiguration.prototype.setCheckInRetryInSeconds = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string wifi_ssid = 16;
 * @return {string}
 */
proto.HeartSeatConfiguration.prototype.getWifiSsid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.HeartSeatConfiguration.prototype.setWifiSsid = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string wifi_pass = 17;
 * @return {string}
 */
proto.HeartSeatConfiguration.prototype.getWifiPass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.HeartSeatConfiguration.prototype.setWifiPass = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool automatic_recording = 18;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.HeartSeatConfiguration.prototype.getAutomaticRecording = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 18, false));
};


/** @param {boolean} value */
proto.HeartSeatConfiguration.prototype.setAutomaticRecording = function(value) {
  jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool automatic_upload = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.HeartSeatConfiguration.prototype.getAutomaticUpload = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.HeartSeatConfiguration.prototype.setAutomaticUpload = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool debug_logging = 20;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.HeartSeatConfiguration.prototype.getDebugLogging = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 20, false));
};


/** @param {boolean} value */
proto.HeartSeatConfiguration.prototype.setDebugLogging = function(value) {
  jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool use_dev_api = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.HeartSeatConfiguration.prototype.getUseDevApi = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.HeartSeatConfiguration.prototype.setUseDevApi = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional RecordingConfiguration recording_config = 32;
 * @return {?proto.RecordingConfiguration}
 */
proto.HeartSeatConfiguration.prototype.getRecordingConfig = function() {
  return /** @type{?proto.RecordingConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.RecordingConfiguration, 32));
};


/** @param {?proto.RecordingConfiguration|undefined} value */
proto.HeartSeatConfiguration.prototype.setRecordingConfig = function(value) {
  jspb.Message.setWrapperField(this, 32, value);
};


proto.HeartSeatConfiguration.prototype.clearRecordingConfig = function() {
  this.setRecordingConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.HeartSeatConfiguration.prototype.hasRecordingConfig = function() {
  return jspb.Message.getField(this, 32) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.HeartSeatStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.HeartSeatStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.HeartSeatStatus.displayName = 'proto.HeartSeatStatus';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.HeartSeatStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.HeartSeatStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.HeartSeatStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeartSeatStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    firmwareVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    utcTimestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    recordingCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    firmwareStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
    firmwareActionAttempts: jspb.Message.getFieldWithDefault(msg, 7, 0),
    firmwareAction: jspb.Message.getFieldWithDefault(msg, 8, 0),
    runtime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    timeOfLastCheckin: jspb.Message.getFieldWithDefault(msg, 10, 0),
    timeOfLastRecording: jspb.Message.getFieldWithDefault(msg, 11, 0),
    batteryVoltage: +jspb.Message.getFieldWithDefault(msg, 12, 0.0),
    bootReason: jspb.Message.getFieldWithDefault(msg, 13, 0),
    bootloaderVersion: jspb.Message.getFieldWithDefault(msg, 14, 0),
    hwRevision: jspb.Message.getFieldWithDefault(msg, 15, ""),
    batteryCoulombs: +jspb.Message.getFieldWithDefault(msg, 16, 0.0),
    batteryCapacity: jspb.Message.getFieldWithDefault(msg, 17, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.HeartSeatStatus}
 */
proto.HeartSeatStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.HeartSeatStatus;
  return proto.HeartSeatStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.HeartSeatStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.HeartSeatStatus}
 */
proto.HeartSeatStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtcTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecordingCount(value);
      break;
    case 6:
      var value = /** @type {!proto.HeartSeatStatus.FirmwareStatus} */ (reader.readEnum());
      msg.setFirmwareStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFirmwareActionAttempts(value);
      break;
    case 8:
      var value = /** @type {!proto.HeartSeatStatus.FirmwareAction} */ (reader.readEnum());
      msg.setFirmwareAction(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRuntime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeOfLastCheckin(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeOfLastRecording(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBatteryVoltage(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBootReason(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBootloaderVersion(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setHwRevision(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBatteryCoulombs(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBatteryCapacity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.HeartSeatStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.HeartSeatStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.HeartSeatStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HeartSeatStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUtcTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRecordingCount();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getFirmwareStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getFirmwareActionAttempts();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getFirmwareAction();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getRuntime();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getTimeOfLastCheckin();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTimeOfLastRecording();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getBatteryVoltage();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getBootReason();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getBootloaderVersion();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getHwRevision();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBatteryCoulombs();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getBatteryCapacity();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.HeartSeatStatus.FirmwareAction = {
  NONE: 0,
  UPDATE: 1,
  ROLLBACK: 2
};

/**
 * @enum {number}
 */
proto.HeartSeatStatus.FirmwareStatus = {
  GOOD: 0,
  UNVERIFIED: 1,
  ROLLED_BACK: 2,
  BAD: 3,
  FLASHING: 4
};

/**
 * optional string firmware_version = 2;
 * @return {string}
 */
proto.HeartSeatStatus.prototype.getFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.HeartSeatStatus.prototype.setFirmwareVersion = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 utc_timestamp = 3;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getUtcTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setUtcTimestamp = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string serial_number = 4;
 * @return {string}
 */
proto.HeartSeatStatus.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.HeartSeatStatus.prototype.setSerialNumber = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 recording_count = 5;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getRecordingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setRecordingCount = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional FirmwareStatus firmware_status = 6;
 * @return {!proto.HeartSeatStatus.FirmwareStatus}
 */
proto.HeartSeatStatus.prototype.getFirmwareStatus = function() {
  return /** @type {!proto.HeartSeatStatus.FirmwareStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {!proto.HeartSeatStatus.FirmwareStatus} value */
proto.HeartSeatStatus.prototype.setFirmwareStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional uint32 firmware_action_attempts = 7;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getFirmwareActionAttempts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setFirmwareActionAttempts = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional FirmwareAction firmware_action = 8;
 * @return {!proto.HeartSeatStatus.FirmwareAction}
 */
proto.HeartSeatStatus.prototype.getFirmwareAction = function() {
  return /** @type {!proto.HeartSeatStatus.FirmwareAction} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {!proto.HeartSeatStatus.FirmwareAction} value */
proto.HeartSeatStatus.prototype.setFirmwareAction = function(value) {
  jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional uint32 runtime = 9;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getRuntime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setRuntime = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 time_of_last_checkin = 10;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getTimeOfLastCheckin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setTimeOfLastCheckin = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 time_of_last_recording = 11;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getTimeOfLastRecording = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setTimeOfLastRecording = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional float battery_voltage = 12;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getBatteryVoltage = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 12, 0.0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setBatteryVoltage = function(value) {
  jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional uint32 boot_reason = 13;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getBootReason = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setBootReason = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 bootloader_version = 14;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getBootloaderVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setBootloaderVersion = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string hw_revision = 15;
 * @return {string}
 */
proto.HeartSeatStatus.prototype.getHwRevision = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.HeartSeatStatus.prototype.setHwRevision = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional float battery_coulombs = 16;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getBatteryCoulombs = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 16, 0.0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setBatteryCoulombs = function(value) {
  jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional uint32 battery_capacity = 17;
 * @return {number}
 */
proto.HeartSeatStatus.prototype.getBatteryCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/** @param {number} value */
proto.HeartSeatStatus.prototype.setBatteryCapacity = function(value) {
  jspb.Message.setProto3IntField(this, 17, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OldHeartSeatConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OldHeartSeatConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.OldHeartSeatConfiguration.displayName = 'proto.OldHeartSeatConfiguration';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OldHeartSeatConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.OldHeartSeatConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OldHeartSeatConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OldHeartSeatConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    wifiSsid: jspb.Message.getFieldWithDefault(msg, 16, ""),
    wifiPass: jspb.Message.getFieldWithDefault(msg, 17, ""),
    useDevApi: jspb.Message.getFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OldHeartSeatConfiguration}
 */
proto.OldHeartSeatConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OldHeartSeatConfiguration;
  return proto.OldHeartSeatConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OldHeartSeatConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OldHeartSeatConfiguration}
 */
proto.OldHeartSeatConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setWifiSsid(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWifiPass(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseDevApi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OldHeartSeatConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OldHeartSeatConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OldHeartSeatConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OldHeartSeatConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWifiSsid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getWifiPass();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUseDevApi();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * optional string wifi_ssid = 16;
 * @return {string}
 */
proto.OldHeartSeatConfiguration.prototype.getWifiSsid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.OldHeartSeatConfiguration.prototype.setWifiSsid = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string wifi_pass = 17;
 * @return {string}
 */
proto.OldHeartSeatConfiguration.prototype.getWifiPass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.OldHeartSeatConfiguration.prototype.setWifiPass = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool use_dev_api = 21;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.OldHeartSeatConfiguration.prototype.getUseDevApi = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 21, false));
};


/** @param {boolean} value */
proto.OldHeartSeatConfiguration.prototype.setUseDevApi = function(value) {
  jspb.Message.setProto3BooleanField(this, 21, value);
};


goog.object.extend(exports, proto);
