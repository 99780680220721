import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  IonAccordion,
  IonAccordionGroup, IonButton,
  IonCard, IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonCol,
  IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonNote,
  IonPage,
  IonProgressBar, IonRow,
  IonText
} from '@ionic/react';
import './WiFi.css';
import {Autocomplete, MenuItem, TextField} from "@mui/material";
import {chevronForwardOutline, eye} from "ionicons/icons";
import ConfirmCancelModal from "../../components/Modal/ConfirmCancelModal";
import semver from "semver/preload";
import {
  firmwareCutoffVersion,
  localStorageRefs,
  seatProcessCheckinCode,
  seatProcessErrorCodeDNSFailed,
  seatProcessErrorCodeErrInval,
  seatProcessErrorCodeErrIO
} from "../../Refs";
import hsi from "../../lib/HeartSeatInterface";
import {SeatProcessEvent} from "../../types/SeatProcessEvent";
import {SignalWifi1Bar, SignalWifi2Bar, SignalWifi3Bar, SignalWifi4Bar} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {Token} from "../../types/Token";
import AuthContext from "../../components/Auth/AuthContext";
import {PairData} from "../../types/PairData";
import PairContext from "../../components/Pair/PairContext";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../../components/Includes/AppLocationContext";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import CloudApiService from "../../services/CloudApiService/CloudApiService";


const WiFi: React.FC = () => {

  const history = useHistory();
  const auth = useContext<Token>(AuthContext);
  const pairContext = useContext<PairData>(PairContext);
  const locationContext = useContext<LocationData>(AppLocationContext);
  const seatSettings = useSeatSettings();
  const ApiService = new CloudApiService(auth);
  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [warningText, setWarningText] = useState<string>('');
  const [warning, setWarning] = useState<boolean>(false);
  const [isNetworkValid, setNetworkIsValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isCloudUrlValid, setIsCloudUrlValid] = useState<boolean>(true);
  const [ssidRecords, setSsidRecords] = useState([{ssid: '', rssi: 0}]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [initialWifiConfig, setInitialWifiConfig] = useState({wifi_ssid: '', wifi_pass: '', cloud_endpoint: ''});
  const [hadCheckinError, setHadCheckinError] = useState<boolean>(false);
  const [hasWifiUpdateConfigError, setHasWifiUpdateConfigError] = useState<boolean>(false);
  const [isSettingConfiguration, setIsSettingConfiguration] = useState(false);
  const [hasBleError, setHasBleError] = useState(false);
  const isSettingConfigurationRef = useRef<boolean>();
  const [serialNumber, setSerialNumber] = useState<string>('');
  isSettingConfigurationRef.current = isSettingConfiguration;

  /**
   * Newer seats that have their wifi password already set will return '*****' when the get_scrubbed_user_cfg
   * command is called. They will return an empty string if it's not set.
   */
  const scrubbedWifiPassword = '*****';

  useEffect(() => {
    console.debug(`Wifi.tsx useEffect() seat: ${pairContext.serialNumber}`);
    getExistingConfig().then(() => {});
    getSeatSettings().then(() => {});
    setSerialNumber(pairContext.serialNumber);
  }, [pairContext]);

  /**
   * Check if seat has newer firmware (supports Wifi list functionality and restricts access to Wifi password).
   * Newer firmware also does NOT update check-in time with the checkin_with_upload command.
   */
  const isNewerFirmware = () => {
    return !!pairContext.firmwareVersion && semver.gte(pairContext.firmwareVersion.toString(), firmwareCutoffVersion);
  }

  /**
   * Get the seat settings and set the local storage values for the seat
   */
  const getSeatSettings = async () => {
    try {
      ApiService.getSeatBySerialNumber(pairContext.serialNumber).then(async (response: any) => {
        console.debug('Wifi.tsx getSeatSettings() API response', response);
        // update the seat settings with the API response.
        seatSettings.parseSettings(response.data);
        console.debug('Wifi.tsx getSeatSettings() seatSettings context', seatSettings);
        localStorage.setItem(localStorageRefs.seatId, response.data.seatId);
        localStorage.setItem(localStorageRefs.patientId, response.data.assignedSeatUserId?.toString() ?? '0');
      });
    } catch (e: any) {
      setErrorText('There was an error getting seat configuration.');
      setError(true);
      setSuccess(false);
      console.error(e);
    }
  }

  /**
   * For seats with firmware >= 0.24.0 can get a list of available networks from the seat
   */
  const getSsidRecords = async () => {
    hsi.handleCmd('get_wifi_list', null).then(async (rawSSIDs) => {
      let formatted: any[] = [];
      const ssidWords = rawSSIDs.split(/\r?\n/);

      for (let i = 0; i < ssidWords.length; i += 2) {
        if (formatted.find((rec) => rec.ssid === ssidWords[i])) continue;
        formatted.push({ssid: ssidWords[i], rssi: ssidWords[i + 1]});
      }

      formatted.sort((a, b) => a.rssi - b.rssi);
      setSsidRecords(formatted);
    }).catch((e) => {
      console.error(e);
    });
  }

  /**
   * Retrieve any existing configuration from the heart seat and autofill it into the form.
   */
  const getExistingConfig = async () => {
    setIsSettingConfiguration(true);
    setWarningText('Attempting to load existing configuration from seat.');
    setWarning(true);

    // Handle differently for different firmwares
    if (isNewerFirmware()) {
      await getConfigForNewerSeatFirmwares()
    } else {
      await getConfigForOlderSeatsFirmwares();
    }
  }

  const mustUpdateWifiConfig = () => {
    return seatSettings.settings.wifi_ssid !== initialWifiConfig.wifi_ssid
      || seatSettings.settings.wifi_pass !== initialWifiConfig.wifi_pass
      || seatSettings.settings.cloud_endpoint !== initialWifiConfig.cloud_endpoint.split('.')[0]
      || hadCheckinError;
  }

  /**
   * Seats with firmware versions >= 0.24.0 will use this method.
   */
  const getConfigForNewerSeatFirmwares = async () => {
    hsi.handleCmd('get_status').then(async (response: any) => {
      seatSettings.parseSettings(response);
      await getSsidRecords();
      setSuccess(true);
      setError(false);
      setWarning(false);
      setSuccessText('Existing configuration was loaded from the Heart Seat!');
      setIsSettingConfiguration(false);
    }).catch((e) => {
      console.error(e);
      setIsSettingConfiguration(false);
      setWarning(false);
    });

    hsi.handleCmd('get_scrubbed_user_cfg').then(async (response: any) => {
      seatSettings.parseSettings(response);
      setInitialWifiConfig(response);

      /**
       * {
       *  cloud_endpoint : "device.casanacare.com"
       *  wifi_pass: "*****" (has wifi password) OR "" (no password is set on seat yet)
       *  wifi_ssid: "Wifi Network Name"
       * }
       */
      setIsSettingConfiguration(false);

    }).catch((e) => {
      if (e.message === "not connected" || e.message === "BLE disconnected") {
        setError(true);
        setSuccess(false);
        setWarning(false);
        setErrorText('Setup Wizard is not connected to a Heart Seat.');
        setHasBleError(true);
      } else {
        console.error(e);
        setError(true);
        setSuccess(false);
        setWarning(false);
        setErrorText('Command get_scrubbed_user_config failed');
        setHasBleError(true);
      }
      setIsSettingConfiguration(false);
    }).catch((e) => {
      console.error(e);
      setWarning(false);
      setIsSettingConfiguration(false);
    });
  }

  /**
   * Seats with firmware versions < 0.24.0 will use this method. They can return a non-sanitized wifi password
   * but they cannot list available wifi networks.
   */
  const getConfigForOlderSeatsFirmwares = async () => {
    hsi.handleCmd('get_status').then(async (response: any) => {
      seatSettings.parseSettings(response);
    }).catch((e) => {
      console.error(e);
      setIsSettingConfiguration(false);
      setWarning(false);
    });

    hsi.handleCmd('file_get_info', "user_cfg").then(async (user_cfg: any) => {
      let data = {
        "path": "user_cfg",
        "index": 0,
        "bytes": user_cfg.fsize
      }

      hsi.handleCmd('file_read_raw', data).then(async (response: any) => {
        let decoder = new TextDecoder();
        let decodedData = JSON.parse(decoder.decode(response));
        seatSettings.parseSettings(decodedData);
        setInitialWifiConfig(decodedData);
        setSuccess(true);
        setError(false);
        setWarning(false);
        setSuccessText('Existing configuration was loaded from the Heart Seat!');
        setIsSettingConfiguration(false);
      }).catch((error) => {
        console.error(error);
        setIsSettingConfiguration(false);
        setWarning(false);
      });
    }).catch((e) => {
      if (e.message === "not connected" || e.message === "BLE disconnected") {
        setError(true);
        setSuccess(false);
        setWarning(false);
        setErrorText('Setup Wizard is not connected to a Heart Seat.');
        setHasBleError(true);
      } else {
        console.error(e);
      }
      setIsSettingConfiguration(false);
    }).catch((e) => {
      console.error(e);
      setWarning(false);
      setIsSettingConfiguration(false);
    });
  }

  /**
   * Set the network name value and show error states appropriately.
   *
   * @param networkName
   */
  const handleNetworkChange = (networkName: string) => {
    if (networkName) {
      setNetworkIsValid(true);
      setError(false);
      seatSettings.settings.wifi_ssid = networkName;
    } else {
      setNetworkIsValid(false);
      setError(true);
      setSuccess(false);
      setErrorText('Network name is invalid.');

      if (networkName.length === 0) {
        seatSettings.settings.wifi_ssid = '';
      }
    }

    if (isNetworkValid && isPasswordValid && isCloudUrlValid) {
      setError(false);
    }
  }

  /**
   * Set the Wi-Fi Network password value and show error states appropriately.
   *
   * @param password
   */
  const handlePasswordChange = (password: string) => {
    if (password && password.length > 3) {
      setIsPasswordValid(true);
      setError(false);
      seatSettings.settings.wifi_pass = password;
    } else {
      setIsPasswordValid(false);
      setError(true);
      setSuccess(false);
      setErrorText('Password is invalid.');

      if (password.length <= 3) {
        seatSettings.settings.wifi_pass = password;
        if (password.length === 0) {
          seatSettings.settings.wifi_pass = '';
        }
      }
    }

    if (isNetworkValid && isPasswordValid && isCloudUrlValid) {
      setError(false);
    }
  }

  /**
   * Set the cloud URL value and show error states appropriately.
   *
   * @param url
   */
  const handeCloudUrlChange = (url: string) => {
    if (url.match('^[\\w\\d-]{2,63}$')) {
      setIsCloudUrlValid(true);
      setError(false);
      seatSettings.settings.cloud_endpoint = url;
    } else {
      setIsCloudUrlValid(false);
      setError(true);
      setSuccess(false);
      setErrorText('Cloud URL is invalid.');

      if (url.length <= 2) {
        seatSettings.settings.cloud_endpoint = url;
        if (url.length === 0) {
          seatSettings.settings.cloud_endpoint = '';
        }
      }
    }

    if (isNetworkValid && isPasswordValid && isCloudUrlValid) {
      setError(false);
    }
  }

  /**
   * Toggle for password field.
   */
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  /**
   * Handle sending the user back to the pair screen.
   */
  const handleBack = () => {
    setErrorText('');
    setError(false);
    setSuccess(false);
    setIsSettingConfiguration(false);
    history.push('/pair');
    locationContext.returnView = '/pair'
    seatSettings.setButtonClass('off');
  }

  const handleNext = async () => {

    /**
     * Handle case of factory reset where everything is empty or any case where one of these fields is empty.
     */
    if (!seatSettings.settings.wifi_ssid || !seatSettings.settings.wifi_pass || !seatSettings.settings.cloud_endpoint) {
      setErrorText('You must set your wifi configuration before proceeding.');
      setError(true);
      return;
    }

    if (isNewerFirmware()) {
      await handleNextForNewFirmware();
    } else {
      await handleNextForOldFirmware();
    }
  }

  /**
   * NOTE: seats with newer firmware return a scrubbed password if it exists on the seat. They also do not allow
   * the methods file_get_info or file_read_raw. To get wifi info they use the get_scrubbed_user_cfg command.
   *
   * If we have valid data for the Wi-Fi configuration, make an API call to the cloud and disable auto_recording and
   * auto_upload. If that is successful, attempt to update the configuration on the seat. Once we have updated the
   * config, retrieve the UserConfig from the seat, and verify it matches what we attempted to set. Once we
   * can verify the seat has the correct config, perform a checkin and make sure that it completes successfully before
   * we send the user to the Patient Details screen.
   */
  const handleNextForNewFirmware = async () => {

    /**
     * If no changes were made, simply move to the next step.
     */
    if (!mustUpdateWifiConfig()) {
      return handleNextStep();
    }

    /**
     * Need to trigger the change events here to make sure the values are appropriately updated as we check them.
     */
    handleNetworkChange(seatSettings.settings.wifi_ssid);
    handeCloudUrlChange(seatSettings.settings.cloud_endpoint);

    /**
     * If a password has already been set on the seat it will return to the UI as '*****'. If is has not, it will
     * return as an empty string. The only way to verify if the password really works is to force checkin below.
     */
    if (seatSettings.settings.wifi_pass !== scrubbedWifiPassword) {
      handlePasswordChange(seatSettings.settings.wifi_pass);
    } else {
      setIsPasswordValid(true);
    }

    if (
      isNetworkValid &&
      isPasswordValid &&
      isCloudUrlValid &&
      seatSettings.settings.wifi_pass !== '' &&
      seatSettings.settings.wifi_ssid !== ''
    ) {
      setErrorText('');
      setError(false);
      setIsSettingConfiguration(true);

      try {
        let wifi_config = {
          wifi_ssid: seatSettings.settings.wifi_ssid,
          /**
           * For Kevin seats password will be "*****" if unchanged by user input which will require
           * re-submitting the password if either of the other wifi config values were changed.
           */
          wifi_pass: seatSettings.settings.wifi_pass,
          cloud_endpoint: seatSettings.settings.cloud_endpoint + '.casanacare.com'
        };

        hsi.handleCmd('set_user_config', wifi_config).then(async () => {
          hsi.handleCmd('get_scrubbed_user_cfg').then(async (response: any) => {
            if (
              response.wifi_ssid === seatSettings.settings.wifi_ssid &&
              response.cloud_endpoint === seatSettings.settings.cloud_endpoint + '.casanacare.com'
            ) {
              await handleForceCheckin();
            } else {
              setError(true);
              setErrorText('There was an error updating WiFi configuration on the seat.');
              setIsSettingConfiguration(false);
            }
          })
        }).catch((e: any) => {
          if (e.message === "not connected") {
            console.error(e);
            history.push('/pair');
            locationContext.returnView = '/pair'
          } else {
            /**
             * For newer seats handle set_user_config wifi update error case here. Older seats just timeout.
             */
            handleWifiConfigError();
            console.error(e);
          }
        })

      } catch (e: any) {
        setErrorText('There was an error setting seat configuration in the cloud.');
        setError(true);
        setSuccess(false);
        setIsSettingConfiguration(false);
        console.error(e);
      }
    } else {
      setError(true);
      setSuccess(false);
      setIsSettingConfiguration(false);
    }

    if (!isPasswordValid || !isNetworkValid || !isCloudUrlValid) {
      setErrorText('Please resolve the incorrect fields below.');
      setIsSettingConfiguration(false);
    }
  }

  /**
   * If we have valid data for the Wi-Fi configuration, make an API call to the cloud and disable auto_recording and
   * auto_upload. If that is successful, attempt to update the configuration on the seat. Once we have updated the
   * config, retrieve the UserConfig from the seat, and verify it matches what we attempted to set. Once we
   * can verify the seat has the correct config, perform a checkin and make sure that it completes successfully before
   * we send the user to the Patient Details screen.
   */
  const handleNextForOldFirmware = async () => {

    /**
     * If no changes were made, simply move to the next step.
     */
    if (!mustUpdateWifiConfig()) {
      return handleNextStep();
    }

    /**
     * Need to trigger the change events here to make sure the values are appropriately updated as we check them.
     */
    handleNetworkChange(seatSettings.settings.wifi_ssid);
    handeCloudUrlChange(seatSettings.settings.cloud_endpoint);
    handlePasswordChange(seatSettings.settings.wifi_pass);

    if (
      isNetworkValid &&
      isPasswordValid &&
      isCloudUrlValid &&
      seatSettings.settings.wifi_pass !== '' &&
      seatSettings.settings.wifi_ssid !== ''
    ) {
      setErrorText('');
      setError(false);
      setIsSettingConfiguration(true);

      try {
        let wifi_config = {
          wifi_ssid: seatSettings.settings.wifi_ssid,
          wifi_pass: seatSettings.settings.wifi_pass,
          cloud_endpoint: seatSettings.settings.cloud_endpoint + '.casanacare.com',
        };

        hsi.handleCmd('set_user_config', wifi_config).then(async () => {
          hsi.handleCmd('file_get_info', "user_cfg").then(async (user_cfg: any) => {

            let data = {
              "path": "user_cfg",
              "index": 0,
              "bytes": user_cfg.fsize
            }

            hsi.handleCmd('file_read_raw', data).then(async (response: any) => {
              /**
               * The seat returns an array of bytes that we need to decode into actual data.
               */
              let decodedData = JSON.parse(new TextDecoder().decode(response));

              if (
                decodedData.wifi_ssid === seatSettings.settings.wifi_ssid &&
                decodedData.wifi_pass === seatSettings.settings.wifi_pass &&
                decodedData.cloud_endpoint === seatSettings.settings.cloud_endpoint + '.casanacare.com'
              ) {
                await handleForceCheckin();
              } else {
                setError(true);
                setErrorText('There was an error updating WiFi configuration on the seat.');
                setIsSettingConfiguration(false);
              }
            })
          })
        }).catch((e: any) => {
          if (e.message === "not connected") {
            console.error(e);
            history.push('/pair');
            locationContext.returnView = '/pair'
          } else {
            console.error(e);
            setError(true);
            setErrorText('There was an error updating WiFi configuration on the seat.');
            setIsSettingConfiguration(false);
          }
        })
      } catch (e: any) {
        setErrorText('There was an error setting seat configuration in the cloud.');
        setError(true);
        setSuccess(false);
        setIsSettingConfiguration(false);
        console.error(e);
      }
    } else {
      setError(true);
      setSuccess(false);
      setIsSettingConfiguration(false);
    }

    if (!isPasswordValid || !isNetworkValid || !isCloudUrlValid) {
      setErrorText('Please resolve the incorrect fields below.');
      setIsSettingConfiguration(false);
    }
  }

  const handleNextStep = () => {
    setIsSettingConfiguration(false);
    setWarning(false)
    setSuccess(false)
    setError(false);
    setErrorText('');
    setWarningText('');
    setSuccessText('');

    if (localStorage.getItem(localStorageRefs.patientId) === '0') {
      history.push('/patient-details/new')
      locationContext.returnView = '/patient-details/new';
    } else {
      history.push('/patient-details/existing')
      locationContext.returnView = '/patient-details/existing';
    }

    seatSettings.setButtonClass('off');
  }

  const handleForceCheckin = async () => {
    let checkinSuccessful = await forceCheckinWaitImpl();

    if (checkinSuccessful) {
      setHadCheckinError(false);
      handleNextStep();
    } else {
      setError(true);
      setSuccess(false);
      setErrorText('Cloud check in failed. Please verify your wifi configuration.');
      setHadCheckinError(true);
      setIsSettingConfiguration(false);
    }
  }

  /**
   * Ask the seat to check in with the cloud and wait for a successful checkin to complete. This is lifted directly
   * from the Connect App.
   **/
  const forceCheckinWaitImpl = async () => {

    setIsSettingConfiguration(true);
    await hsi.handleCmd('checkin');
    hsi.registerProcessEventHandler(forceCheckinListener);

    /**
     * Listen for checkin completed event.
     */
    for (let i = 0; i < 30; i++) {
      await new Promise((r) => setTimeout(r, 1000));

      /* see if we have a new last checkin time */
      if (!isSettingConfigurationRef.current) {
        hsi.unregisterAllProcessEventHandlers();
        return true;
      }
    }

    hsi.unregisterAllProcessEventHandlers();
    return false;
  }

  const forceCheckinListener = (ev: SeatProcessEvent) => {
    console.debug('FORCE CHECKIN LISTENER', ev);

    if (hasSeatCompletedCheckin(ev)) {
      setIsSettingConfiguration(false);
    }
  }

  /**
   * Verify if checkin completed successfully. We also have to listen for certain firmware-specific error codes which
   * either indicate the wifi connection failed, the cloud endpoint could not be reached, or the seat failed to
   * update for whatever reason.
   *
   * @param ev
   */
  const hasSeatCompletedCheckin = (ev: SeatProcessEvent): boolean => {
    return ev.proc_magic === seatProcessCheckinCode
      && typeof ev.error !== 'undefined'
      && ev.error !== seatProcessErrorCodeDNSFailed
      && ev.error !== seatProcessErrorCodeErrIO
      && ev.error !== seatProcessErrorCodeErrInval;
  }

  /**
   * For newer firmware seats that return wifi lists. Returns icons displaying signal strength.
   *
   * @param wifiName
   */
  const getWifiIcon = (wifiName: string) => {

    let rssi = ssidRecords.find((rec) => wifiName === rec.ssid)?.rssi;

    if (!rssi) {
      return;
    }

    if (rssi < 57) {
      return <SignalWifi4Bar color="action"/>;
    } else if (rssi < 70) {
      return <SignalWifi3Bar color="action"/>;
    } else if (rssi < 80) {
      return <SignalWifi2Bar color="action"/>;
    } else {
      return <SignalWifi1Bar color="action"/>;
    }
  }

  const handleWifiConfigError = () => {
    setHasWifiUpdateConfigError(true);
    setIsSettingConfiguration(false);
  }

  const closeWifiConfigErrorModal = () => {
    setHasWifiUpdateConfigError(false);

  }

  const handlePairAgain = () => {
    setHasBleError(false);

    setTimeout(function () {
      handleBack();
    }, 50)
  }

  // Hack to submit form if enter key is pressed.
  const checkEnter = async (e: any) => {
    if (e.key === 'Enter') {
      await handleNext();
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
          <IonText className="danger">
            {errorText}
          </IonText>
        </IonCard>
        <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
          <IonText className="success">
            {successText}
          </IonText>
        </IonCard>
        <IonCard className={warning ? 'ion-show flash-message' : 'ion-hide flash-message'}>
          <IonText className="warning">
            {warningText}
          </IonText>
        </IonCard>
        <IonCard className="standard-container casana-form">
          <IonCardHeader>
            <IonCardTitle>Connect to wifi</IonCardTitle>
            <IonCardSubtitle>&nbsp;Seat {serialNumber}</IonCardSubtitle>
            {isSettingConfiguration ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
          </IonCardHeader>
          <IonCardContent className="wifi-page">
            <IonGrid className="wifi-form casana-form">
              <IonRow>
                <IonCol size="12" size-md="6">
                  <IonLabel>Wifi network name</IonLabel>
                  <IonItem className={isNetworkValid ? 'ion-valid' : 'ion-invalid'}>
                    {/* For older firmware that don't have wifi lists */}
                    <IonInput
                      className={`${!isNewerFirmware() && 'ion-show'} ${isNewerFirmware() && 'ion-hide'}`}
                      required={true}
                      autofocus={true}
                      autocomplete={"off"}
                      inputmode={"text"}
                      maxlength={255}
                      debounce={300}
                      type="text"
                      placeholder="Network"
                      onIonChange={(e: any) => handleNetworkChange(e.detail.value)}
                      value={seatSettings.settings.wifi_ssid}
                    />

                    {/* For newer firmware with wifi lists. Mui Component was needed due to Ionic not being able to display traditional dropdown with icons*/}
                    <Autocomplete
                      forcePopupIcon={true}
                      freeSolo
                      className={isNewerFirmware() ? 'ion-show' : 'ion-hide'}
                      size='small'
                      sx={{
                        width: '100%',
                        fieldset: {border: 'none'}
                      }}
                      value={seatSettings.settings.wifi_ssid}
                      onChange={(event, newValue) => handleNetworkChange(newValue ?? '')}
                      onInputChange={(event, newInputValue) => handleNetworkChange(newInputValue ?? '')}
                      options={ssidRecords.map((rec) => rec.ssid)}
                      renderInput={(params) => <TextField {...params} label='&nbsp;'/>}
                      renderOption={(props, option) => (
                        <MenuItem {...props}>
                          {getWifiIcon(option)}&nbsp;
                          {option ? option : '(Hidden Network)'}
                        </MenuItem>
                      )}
                    />
                    <IonNote slot="error">Network is a required field.</IonNote>
                  </IonItem>
                </IonCol>
                <IonCol size="12" size-md="6">
                  <IonLabel>Password</IonLabel>
                  <IonItem className={isPasswordValid ? 'ion-valid' : 'ion-invalid'}>
                    <IonIcon slot="end" ios={eye} md={eye} onClick={() => toggleShowPassword()}/>
                    <IonInput
                      required={true}
                      inputmode={"text"}
                      autocomplete={"off"}
                      autocorrect={"off"}
                      maxlength={255}
                      debounce={300}
                      type="password"
                      placeholder="Password"
                      onIonChange={(e: any) => handlePasswordChange(e.detail.value)}
                      onKeyUp={(e: any) => checkEnter(e)}
                      value={seatSettings.settings.wifi_pass}
                      className={showPassword ? 'ion-hide network-password' : 'ion-show network-password'}

                    />
                    <IonInput
                      required={true}
                      inputmode={"text"}
                      autocomplete={"off"}
                      autocorrect={"off"}
                      maxlength={255}
                      debounce={300}
                      type="text"
                      placeholder="Password"
                      onIonChange={(e: any) => handlePasswordChange(e.detail.value)}
                      onKeyUp={(e: any) => checkEnter(e)}
                      value={seatSettings.settings.wifi_pass}
                      className={showPassword ? 'ion-show cloud-url' : 'ion-hide cloud-url'}
                    />
                    <IonNote slot="error">Password is a required field.</IonNote>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonAccordionGroup className="pair-accordion no-ripple">
              <IonAccordion value="first" className="no-ripple" toggleIconSlot="start" toggleIcon={chevronForwardOutline}>
                <IonItem slot="header" className="color-app">
                  <IonLabel className="pair-accordion-text">Advanced options</IonLabel>
                </IonItem>
                <IonGrid slot="content" className="casana-form">
                  <IonRow>
                    <IonCol size="4" size-md="4" className="ion-margin-start">
                      <IonLabel>Cloud endpoint</IonLabel>
                      <IonItem className={isCloudUrlValid ? 'ion-valid' : 'ion-invalid'}>
                        <IonInput
                          required={true}
                          inputmode={"text"}
                          autocomplete={"off"}
                          autocorrect={"off"}
                          maxlength={255}
                          type="text"
                          placeholder="URL"
                          onIonChange={(e: any) => handeCloudUrlChange(e.detail.value)}
                          value={seatSettings.settings.cloud_endpoint}
                        />
                        <IonNote slot="error">Cloud endpoint is a required field.</IonNote>
                      </IonItem>
                    </IonCol>
                    <IonCol size="4" size-md="4" className="d-flex">
                      <IonText className="ion-align-self-end cloud-endpoint-text">.casanacare.com</IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
            </IonAccordionGroup>
            <IonAccordionGroup className="pair-accordion no-ripple">
              <IonAccordion value="first" className="no-ripple" toggleIconSlot="start" toggleIcon={chevronForwardOutline}>
                <IonItem slot="header" className="color-app">
                  <IonLabel className="pair-accordion-text">Tips for connecting to wifi</IonLabel>
                </IonItem>
                <IonGrid slot="content">
                  <IonRow>
                    <IonText className="ion-padding">
                      If you’re having trouble connecting to wifi, try the following:
                    </IonText>
                  </IonRow>
                  <IonRow>
                    <IonText>
                      <ul>
                        <li>Confirm that the network name and password are correct. These fields are case sensitive.</li>
                        <br/>
                        <li>
                          If the desired network name does not appear in the wifi dropdown options, you can enter it by typing directly in the field.
                        </li>
                      </ul>
                    </IonText>
                  </IonRow>
                </IonGrid>
              </IonAccordion>
            </IonAccordionGroup>
          </IonCardContent>
          <IonCardContent className="standard-container-footer">
            <IonButton className="btn btn-type-code" expand="block" onClick={handleBack}>Back</IonButton>
            <IonButton
              className="btn btn-scan-code"
              expand="block"
              onClick={handleNext}
            >Next</IonButton>
          </IonCardContent>
        </IonCard>
        <ConfirmCancelModal
          isOpen={hasWifiUpdateConfigError}
          headerText="An Error Occurred..."
          subheaderText="Please ensure your wifi credentials are correct and try again."
          onButtonAction1={closeWifiConfigErrorModal}
          actionButtonText1="Try Again"
          showWarningIcon={true}
          bigHeader
        />
        <ConfirmCancelModal
          isOpen={hasBleError}
          headerText="Seat Disconnected"
          subheaderText="You are no longer connected to a Heart Seat. Please pair to a seat in order to continue."
          onButtonAction1={handlePairAgain}
          actionButtonText1="Pair Seat"
          showWarningIcon={true}
          bigHeader
        />
      </IonContent>
    </IonPage>
  );
};

export default WiFi;
